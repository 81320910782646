/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import moment from 'moment';
import get from 'lodash/get';
import { symbolValidationCheck } from '../DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';
import TooltipComponent from '../../Tooltip';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
const { ValueContainer, Placeholder } = components;

const NewValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.selectProps.menuIsOpen || props.hasValue}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const StyleObject = (error) => {
  return {
    menu: (styles) => ({ ...styles, zIndex: 999 }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      zIndex: 999999,
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflowX: 'hidden',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      color: 'rgba(103, 105, 111, 0.5)',
      top:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 15
          : '50%',
      transition: 'top 0.1s, font-size 0.1s',
      whiteSpace: 'nowrap',
      fontSize:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 11
          : 13,
    }),
    control: (base) => ({
      ...base,
      height: 49,
      minHeight: 49,
      ...(error
        ? {
            border: '1px solid  #9d323d',
            '&:hover': { borderColor: '#9d323d' },
            boxShadow: 'none',
          }
        : { boxShadow: 'none' }),
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 49,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      top: 35,
    }),
  };
};

const MonthYearDropdown = (props) => {
  const {
    id,
    className,
    name,
    label,
    monthList,
    yearList,
    isClearable,
    isSearchable,
    isMulti,
    customStyle,
    dataValidations,
    requiredValidation = [],
    customError,
    control,
    value,
    connectorField,
    connectorType,
    connectorMessage,
    setValue,
    formName,
    readOnly,
    validateOnLoad,
    t,
    clearErrors,
    register,
    isHideField,
    showBasedOnFieldName,
    hideBasedOnFieldName,
    placeholder,
    watch,
    fieldName,
    incrementValue,
    getValues,
    trigger,
    sitecoreContext,
  } = props;
  const fieldError = get(customError, name);
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const isSwiss = sitecoreContext?.Country?.name?.toLowerCase() === 'switzerland';

  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  const yearOrMonthSelect = getValidationDatabyType(
    dataValidations,
    'yearOrMonthSelectValidator'
  );
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const connectorFormName =
    formName && connectorField ? `${formName}[${connectorField}]` : connectorField;
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const connectoreFieldsValue = connectorFormName ? watch(connectorFormName) : null;
  const fieldValue = name ? watch(name) : null;
  const hiddenMonthName =
    formName && fieldName
      ? `${formName}[${fieldName}_month_hidden]`
      : `${fieldName}_month_hidden`;
  const hiddenYearName =
    formName && fieldName
      ? `${formName}[${fieldName}_year_hidden]`
      : `${fieldName}_year_hidden`;
  const labelVals = placeholder?.split('/');
  const monthLabel = labelVals && labelVals?.length > 0 ? labelVals[0] : '';
  const yearLabel = labelVals && labelVals?.length > 1 ? labelVals[1] : '';
  const [error, setError] = useState(false);

  const getDate = (dt) => {
    let result = null;
    if (dt && dt !== null) {
      if (typeof dt === 'number') {
        const theDate = new Date(parseInt(dt));
        const dateString = theDate.toGMTString();
        result = dateString;
      } else {
        result = dt;
      }
      if (moment(result).isValid()) {
        result = new Date(result);
        result.setDate(1);
      }
      return moment(result).isValid()
        ? moment(result).format('YYYY-MM-DDTHH:mm:ss')
        : null;
    }
    return result;
  };

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  const getMonth = (month) => {
    let result = {};
    month = month.toString();
    result = monthList?.find(function (item) {
      return item.value === month || item.label === month;
    });
    return result;
  };

  const getYear = (year) => {
    let result = {};
    year = year.toString();
    result = yearList?.find(function (item) {
      return item.value === year || item.label === year;
    });
    return result;
  };

  useEffect(() => {
    param = value ? { ...param, shouldDirty: true } : param;
    if (value) {
      const dateValue = getDate(value);
      const month = dateValue ? (new Date(dateValue).getMonth() + 1).toString() : '';
      const year = dateValue ? new Date(dateValue).getFullYear().toString() : '';
      let monthObj = getMonth(month);
      let yearObj = getYear(year);
      if (
        monthObj &&
        yearObj &&
        Object.keys(monthObj).length > 0 &&
        Object.keys(yearObj).length > 0
      ) {
        setValue(hiddenMonthName, monthObj);
        setValue(hiddenYearName, yearObj);
        setValue(name, dateValue, param);
      }
    } else {
      setValue(hiddenMonthName, null);
      setValue(hiddenYearName, null);
      setValue(name, null, param);
    }
  }, [value]);

  const dateFilter = (
    date,
    month,
    year,
    incr = 0,
    format = 'YYYY-MM-DDTHH:mm:ss'
  ) => {
    date = date ? new Date(date) : new Date();
    date.setDate(1);
    if (month) date.setMonth(parseInt(month) - 1);
    if (year) date.setFullYear(parseInt(year));
    if (incr > 0 && !year) {
      const currentYear = date.getFullYear();
      const endYear = currentYear + parseInt(incr);
      date.setFullYear(endYear);
    }
    date = moment(date).format(format);
    return date;
  };

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let incre = parseInt(incrementValue) + 1;
      let futureDate = dateFilter(null, 12, null, incre, 'YYYY-MM-DD');
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
      let isValid = dateValidator(fieldValue);
      if (isFieldHasVal && (!fieldValue || !isValid || !value)) {
        setValue(hiddenMonthName, null);
        setValue(hiddenYearName, null);
        setValue(name, futureDate, param);
      } else if (fieldValue === futureDate) {
        setValue(name, null, param);
      }
    }
  }, [hideFieldValue, value]);

  const dateValidator = (val) => {
    let result = true;
    if (val && connectoreFieldsValue) {
      result = symbolValidationCheck(val, connectoreFieldsValue, connectorType);
      if (result) {
        clearErrors(connectorFormName);
      }
    }
    return result;
  };

  const onMonthSelect = (month) => {
    let dateField = fieldValue;
    let year = getValues(hiddenYearName);
    year = year?.value ? year.value : year;
    if (
      (year && (month === null || month == undefined)) ||
      (month && (year === null || year === undefined))
    ) {
      setError(true);
    } else {
      setError(false);
    }
    setValue(name, month && year ? dateFilter(dateField, month, year) : null, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setTimeout(() => {
      trigger(connectorFormName);
    }, 10);
  };

  const onYearSelect = (year) => {
    let dateField = fieldValue;
    let month = getValues(hiddenMonthName);
    month = month?.value ? month.value : month;
    if (
      (year && (month === null || month == undefined)) ||
      (month && (year === null || year === undefined))
    ) {
      setError(true);
    } else {
      setError(false);
    }
    setValue(name, month && year ? dateFilter(dateField, month, year) : null, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setTimeout(() => {
      trigger(connectorFormName);
    }, 10);
  };

  const yearOrMonthSelectValidator = () => {
    return !error;
  };

  return (
    <div
      className={`form-block select-complex ${fieldError ? 'error withoutBg' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
      role="combobox"
    >
      <div className="monthYearWrapper">
        <div className="monthPicker">
          <Controller
            className={className}
            name={hiddenMonthName}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <ReactSelect
                name={hiddenMonthName}
                onChange={(e) => {
                  onChange(e);
                  onMonthSelect(e?.value);
                }}
                onBlur={onBlur}
                options={monthList}
                value={value || ''}
                defaultValue={value || ''}
                autocomplete={false}
                isMulti={isMulti}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={readOnly}
                control={control}
                placeholder={monthLabel}
                styles={StyleObject(fieldError)}
                components={{ ValueContainer: NewValueContainer }}
              />
            )}
          />
        </div>
        <div className="yearPicker">
          <Controller
            className={className}
            name={hiddenYearName}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <ReactSelect
                name={hiddenYearName}
                onChange={(e) => {
                  onChange(e);
                  onYearSelect(e?.value);
                }}
                onBlur={onBlur}
                options={yearList}
                value={value || ''}
                autocomplete={false}
                isMulti={isMulti}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={readOnly}
                control={control}
                placeholder={yearLabel}
                styles={StyleObject(fieldError)}
                components={{ ValueContainer: NewValueContainer }}
              />
            )}
          />
        </div>
        {isSwiss && (
          <div className="info-placeholder">
            <TooltipComponent text={t('date-help-info')}>
              <div className="info-icon">i</div>
            </TooltipComponent>
          </div>
        )}
      </div>
      <input
        type="hidden"
        name={name}
        id={`${name}_hidden`}
        ref={
          register
            ? register({
                required:
                  showField && requiredData?.fields?.value?.value
                    ? !hideField
                    : false,
                validate: {
                  dateValidator: (value) =>
                    (connectorField ? dateValidator(value) : true) ||
                    connectorMessage ||
                    t('validation-failed'),
                  yearOrMonthSelectValidator: () =>
                    (yearOrMonthSelect?.fields?.value?.value === 'true' ||
                    yearOrMonthSelect?.fields?.value?.value === true
                      ? yearOrMonthSelectValidator()
                      : true) ||
                    yearOrMonthSelect?.fields?.message?.value ||
                    t('validation-failed'),
                },
              })
            : () => {}
        }
        aria-required={
          showField && requiredData?.fields?.value?.value ? !hideField : false
        }
      />
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

MonthYearDropdown.defaultProps = {
  id: '',
  label: '',
  name: '',
  placeholder: '',
  customStyle: {},
  readOnly: false,
  value: '',
  setValue: () => {},
  t: () => {},
  dateFormat: 'LL',
  incrementValue: 0,
  trigger: () => {},
};

MonthYearDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  customStyle: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  t: PropTypes.func,
  incrementValue: PropTypes.number,
};

export default withSitecoreContext()(MonthYearDropdown);
