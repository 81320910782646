/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isNullorEmpty, youtubeParser } from '../../../../utils/helperUtils';

const SingleJob = ({ t, sitecoreContext, ...props }) => {
  const { fieldsData: { items, JobDetailFlags } = {}, jobListing } = props || {};
  const {
    jobAdvertisementTeaser = '',
    publicDescription = '',
    companyDescription = '',
    openingParagraph = '',
    requirements = '',
    videoURL = '',
  } = items || {};
  // const [requirement, setRequirement] = useState();
  // const [publicDesc, setPublicDesc] = useState();
  // const [companyDesc, setCompanyDesc] = useState();
  // const [openingPara, setOpeningPara] = useState();
  // const [jobAdVideoUrl, setJobAdVideodUrl] = useState();

  // useEffect(() => {
  //   setPublicDesc(publicDescription);
  //   setCompanyDesc(companyDescription);
  //   setOpeningPara(openingParagraph);
  //   setRequirement(requirements);
  //   setJobAdVideodUrl(videoURL);
  // }, [
  //   publicDescription,
  //   companyDescription,
  //   openingParagraph,
  //   requirements,
  //   videoURL,
  // ]);

  return (
    <div className="single-job fullscreen">
      <div className="details-rich-text">
        {!isNullorEmpty(jobAdvertisementTeaser) && (
          <h2>
            <p className="large job-teaser" role="heading" aria-level="2">
              {jobAdvertisementTeaser}
            </p>
          </h2>
        )}

        {!isNullorEmpty(videoURL) &&
          (videoURL.includes('vimeo') ? (
            <iframe
              title={t('job-ad-video')}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              width="700"
              height="500"
              src={videoURL}
            />
          ) : (
            <iframe
              title={t('job-ad-video')}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              width="700"
              height="500"
              src={`https://www.youtube.com/embed/${youtubeParser(videoURL)}`}
            />
          ))}

        {openingParagraph && (
          <p>
            <RichText field={{ value: openingParagraph }} />
          </p>
        )}

        {JobDetailFlags?.enableJobDetailHeader && (
          <h2>
            <p className="large header-desc" role="heading" aria-level="2">
              {publicDescription && t('header-funct-public')}
            </p>
          </h2>
        )}
        {publicDescription && (
          <p>
            <RichText field={{ value: publicDescription }} />
          </p>
        )}

        {JobDetailFlags?.enableJobDetailHeader && (
          <h2>
            <p className="large header-desc" role="heading" aria-level="2">
              {companyDescription && t('header-over-companydesc')}
            </p>
          </h2>
        )}
        {companyDescription && (
          <p>
            <RichText
              field={{ value: companyDescription }}
              // tag="p"
              aria-hidden={jobListing}
            />
          </p>
        )}

        {JobDetailFlags?.enableJobDetailHeader && (
          <h2>
            <p className="large header-desc" role="heading" aria-level="2">
              {requirements && t('header-requirements')}
            </p>
          </h2>
        )}
        {requirements && (
          <p>
            <RichText field={{ value: requirements }} />
          </p>
        )}
      </div>
    </div>
  );
};

export default withSitecoreContext()(withTranslation()(SingleJob));
