import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import ModalComponent from '../../core/Modal';
import Button from '../globals/buttons/Button';
import Select from 'react-select';
import './languagesmodal.scss';
import '../../core/Forms/forms.scss';
import LocationSearch from '../globals/forms/LocationSearch';
import { isObjNotEmpty } from '../../../../src/utils/helperUtils';
import { dataFetcher } from '../../../dataFetcher';
import {
  JOB_CATEGORY_MASTER_DATA,
  CANDIDATE_PREFERED_LANG,
} from '../../../constants';

const PreferredLanguageModal = (props) => {
  const { t, sitecoreContext } = props;
  const loginReducer = useSelector((state) => state.loginReducer);
  const newUserCheck = loginReducer?.user?.newUser;
  const [jobItems, setJobItems] = useState([]);
  const isSwiss = sitecoreContext?.Country?.name?.toLowerCase() === 'switzerland';

  const getLanguageListForSwitzerland = (sitecoreContext) => {
    const sitecoreJssCommonArray =
      sitecoreContext?.route?.placeholders['jss-common'];

    const getCountryLinksManpowerComponentArray = sitecoreJssCommonArray.find(
      (eachItem) => eachItem.componentName === 'CountryLinksManpower'
    );

    if (!!getCountryLinksManpowerComponentArray) {
      const ItemArrayFromCountryLinksManpower =
        getCountryLinksManpowerComponentArray?.fields?.items;
      const getEuropeItem = ItemArrayFromCountryLinksManpower.find(
        (eachItem) => eachItem.regionName === 'Europe'
      );

      if (!!getEuropeItem) {
        const contryArray = getEuropeItem.countries;
        const getSwitzerlandItem = contryArray.find(
          (eachItem) => eachItem.countryName === 'Switzerland'
        );

        if (!!getSwitzerlandItem) {
          const getSwizLanguages = getSwitzerlandItem.languages;

          const convertArrayIntoSelectOption = getSwizLanguages.map((eachItem) => {
            return { label: eachItem.name, value: eachItem.name };
          });

          return convertArrayIntoSelectOption;
        }
        return null;
      }
      return null;
    }
    return null;
  };

  useEffect(() => {
    if (!!isSwiss) {
      dataFetcher(JOB_CATEGORY_MASTER_DATA.GET_URL, 'GET').then((response) => {
        setJobItems(response?.data);
      });
    }
  }, []);

  const switLangList = getLanguageListForSwitzerland(sitecoreContext);
  const jobOptions = jobItems;
  const convertArrayIntoJobOption = jobOptions.map((eachItem) => {
    return { label: eachItem.name, value: eachItem.id };
  });
  const jobList = convertArrayIntoJobOption;
  const [showModal, setShowModal] = useState(true);
  const countryCode = sitecoreContext?.Country?.code;
  const searchLocationPlaceholder = t('desired-location-zip-code');
  const { locationState } = props;
  const [searchLocation, setSrLocation] = useState({});

  const handleBlur = () => {
    setTimeout(() => {
      if (
        document &&
        document.getElementById('sr-location-hdr') !== document.activeElement
      ) {
      }
    }, 500);
  };
  const [formVal, setFormVal] = useState({
    zipCode: '',
    JobFunction: '',
    language: '',
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    register,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      zipCode: searchLocation?.place,
    },
  });

  useMemo(() => {
    if (isObjNotEmpty(locationState)) {
      setFormVal('zipCode', locationState.place);
      setSrLocation(locationState);
    }
  }, [locationState]);

  const locationSelected = (locVal) => {
    if (locVal) {
      setSrLocation(locVal);
      setValue('zipCode', locVal.place);
      clearErrors('zipCode');
    } else {
      setSrLocation({});
    }
  };

  const onInputChange = (value) => {
    setValue('zipCode', value);
    if (value === '') {
      setSrLocation({});
      clearErrors('zipCode');
    }
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const onSubmit = (data) => {
    const location = data?.zipCode;
    const locationSplit = location.split(',')[0];
    const getZipOnly = locationSplit.split(' ')[0];
    const language = data?.language.value;
    const jobcategory = data?.JobFunction.value;

    const postData = {
      location: getZipOnly,
      language,
      jobcategory,
    };
    dataFetcher(CANDIDATE_PREFERED_LANG.POST_URL, 'POST', postData).then(
      (response) => {
        if (response?.data?.status && response?.data?.status === 1000) {
          setShowModal(false);
        }
      }
    );
  };

  const colourStyles = {
    control: (styles, provided, state, isFocused) => ({
      ...styles,
      cursor: 'pointer',
      border: isFocused ? '2px solid black' : '1px solid gray',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black', // Border color on hover
      },
    }),
  };

  return (
    <>
      {!!isSwiss && newUserCheck === true ? (
        <ModalComponent
          showModal={showModal}
          handleModal={handleModal}
          size="lg"
          backdrop="static"
        >
          <div className="inner-modal-container languageModal" key="body">
            <h2 className="title">{t('additional-info')}</h2>
            <p className="sub-title">{t('additional-info-details')}</p>
            <div className="row pt-4 ">
              <div className="col-lg-8 offset-lg-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row forms">
                    <div className="col-lg-12 form-block text-left">
                      <div className="zip-code form-block">
                        <Controller
                          as={LocationSearch}
                          control={control}
                          onLocationSelect={locationSelected}
                          region={countryCode}
                          errorMessage={t('invalid-location')}
                          label={searchLocationPlaceholder}
                          id="career-search-location"
                          name="zipCode"
                          disableEnterSubmit={true}
                          rules={{ required: t('desired-location-zip-code-req') }}
                          refVal={register}
                          showError={errors?.careerSearchLoc}
                          selectedLocation={searchLocation}
                          onInputChange={onInputChange}
                          focusOutHandler={handleBlur}
                          defaultValue={searchLocation?.place}
                          showCurrentLocation={true}
                        />
                        {errors.zipCode && (
                          <span className="error-msg">{errors.zipCode.message}</span>
                        )}{' '}
                      </div>
                    </div>
                    <div className="col-lg-12 form-block text-left">
                      <div className="job-select">
                        <div className={`form-block jobs`}>
                          <Controller
                            name="JobFunction"
                            control={control}
                            placeholder={t('job-functions')}
                            render={({ field, value }) => (
                              <Select
                                {...field}
                                closeMenuOnSelect={true}
                                placeholder={t('job-functions')}
                                options={jobList}
                                menuPlacement="auto"
                                value={value}
                                styles={colourStyles}
                                onChange={(selectedOptions) => {
                                  setFormVal({
                                    ...formVal,
                                    JobFunction: selectedOptions,
                                  });
                                  setValue('JobFunction', selectedOptions);
                                }}
                              />
                            )}
                            rules={{ required: t('job-function-req') }}
                          />
                        </div>
                      </div>
                      {errors.JobFunction && (
                        <div className="error-msg">{errors.JobFunction.message}</div>
                      )}
                    </div>

                    <div className="col-lg-12 form-block text-left">
                      <div className="language-select">
                        <div className={`form-block skills`}>
                          <Controller
                            name="language"
                            control={control}
                            placeholder={t('user-preferred-language')}
                            render={({ field, value }) => (
                              <Select
                                {...field}
                                closeMenuOnSelect={true}
                                placeholder={t('user-preferred-language')}
                                options={switLangList}
                                menuPlacement="auto"
                                value={value}
                                styles={colourStyles}
                                onChange={(selectedOptions) => {
                                  setFormVal({
                                    ...formVal,
                                    language: selectedOptions,
                                  });
                                  setValue('language', selectedOptions);
                                }}
                              />
                            )}
                            rules={{ required: t('user-preferred-language-req') }}
                          />
                        </div>
                      </div>
                      {errors.language && (
                        <div className="error-msg">{errors.language.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="add-more">
                    <Button text={t('save-change')} type="submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ModalComponent>
      ) : null}
    </>
  );
};

export default withSitecoreContext()(withTranslation()(PreferredLanguageModal));
