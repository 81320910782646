/* eslint-disable */
import React, { useState } from 'react';
import { attachementDownloadApi } from '../../../../../../services/apiServices/candidateService';
import get from 'lodash/get';
import {
  filterArrayByType,
  replaceTextwithJSX,
} from '../../../../../../utils/helperUtils';
import {
  defineKeyValueObjFromObjectArray,
  replaceTextWithValues,
} from '../../../../Utils/helper';
import parse from 'html-react-parser';
import moment from 'moment';
import TooltipComponent from '../../../../../core/Tooltip';
import { Link } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import Modal from '../../../../../core/Modal';
import Button from '../../../../../core/globals/buttons/Button';
import { toast } from 'react-toastify';

const ProfileItemTemplate = ({
  block,
  data,
  candidateId,
  t,
  itemNumber,
  sitecoreContext,
  cssClass,
  id,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [apiPoint, setApiPoint] = useState('');
  const [file, setFile] = useState(null);
  const getItemSplitValues = (item, key, splitKey = '/') => {
    return item?.fields?.[key]?.value
      ? item?.fields?.[key]?.value?.split(splitKey)
      : [];
  };
  const isSwiss = sitecoreContext?.Country?.name?.toLowerCase() === 'switzerland';
  let formDetails = id !== null ? data?.data?.[id] : data?.data;
  let attachments = data?.attachment || [];

  const getItemValues = (item, key, defaultValue = '') =>
    item?.fields?.[key]?.value ? item?.fields?.[key]?.value : defaultValue;

  const getFieldValues = (item, splitKey = '/') => {
    const obj = {};
    obj['apiEndPoint'] = getItemValues(item, 'apiEndPoint');
    obj['name'] = getItemSplitValues(item, 'name', splitKey);
    obj['subLabel'] = getItemSplitValues(item, 'subLabel');
    obj['separator'] = getItemValues(item, 'separator', ' ');
    obj['label'] = getItemValues(item, 'label', '');
    obj['type'] = getItemValues(item, 'type', '');
    obj['defaultValue'] = getItemValues(item, 'value', '');
    obj['connectorField'] = getItemSplitValues(item, 'connectorField');
    obj['format'] = getItemSplitValues(item, 'format');
    obj['singleDateFormat'] = getItemValues(item, 'format');
    obj['showMissingText'] = getItemSplitValues(item, 'showMissingText');
    obj['multipleRequired'] = getItemSplitValues(item, 'multipleRequired', splitKey);

    return obj;
  };

  const getAddress = (item) => {
    const fields = getFieldValues(item, '//');
    let result = '';
    let reqCount = 0;
    let sep = '';
    fields.name.map((n, i) => {
      const nameArr = n?.split('/');
      const reqArr = fields?.multipleRequired?.[i]?.split('/');
      let temp = '';
      let valueArray = [];
      nameArr.map((val, index) => {
        const value = get(formDetails, val);
        sep = fields?.separator ? fields?.separator : '';
        if (value) {
          valueArray.push(value);
        }
        if (!value && reqArr?.[index] === 'true') {
          reqCount++;
        }
      });
      let finalValue = valueArray?.toString();
      temp +=
        valueArray.length > 0
          ? `<div class="textItem">${finalValue.replace(/,/g, sep)}</div>`
          : '';
      result += `<div class="row col">${temp}</div>`;
    });
    let errorTemp =
      reqCount > 0 && block?.fields?.errorText?.value
        ? replaceTextwithJSX(
            block?.fields?.errorText?.value,
            '{0}',
            <Link
              to={block?.fields?.ctaUrl?.value?.href}
              key={`personalInfo${itemNumber}`}
            >
              {block?.fields?.ctaText?.value}
            </Link>
          )
        : '';
    return result ? (
      <>
        {parse(result)}
        <div className="address-error-text">{errorTemp}</div>
      </>
    ) : (
      ''
    );
  };

  const downloadSuccess = (fileName) => {
    toast.success(`${t('download-success-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const errorCallBack = (fileName) => {
    toast.error(`${t('download-error-message')} ${fileName}`, {
      delay: 2000,
    });
  };

  const handleDownloadModal = () => {
    setShowModal(!showModal);
  };

  const download = (file, apiEndPoint) => {
    setFile(file);
    setApiPoint(apiEndPoint);
    handleDownloadModal();
  };

  const handleYes = () => {
    const endpoint = apiPoint?.replace('{cid}', `cid=${candidateId}`);
    toast.success(`${t('downloading')} ${file?.name}`, {
      autoClose: 3000,
    });
    attachementDownloadApi(
      file?.name,
      `${endpoint}&fileID=${file?.id}`,
      downloadSuccess,
      errorCallBack
    );
    handleDownloadModal();
  };

  const handleDownloadOnFocus = (e, item, apiEndPoint) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      download(item, apiEndPoint);
    }
  };

  const handleNo = () => handleDownloadModal();

  /* Show more button 
  const handleTextClick = () => {
    setShowMore(!showMore);
  };*/

  const parseFileValue = (value, apiEndPoint, getAllFiles = false) => {
    let template = [];
    let dataValue = [];
    dataValue = getAllFiles ? attachments : filterArrayByType(attachments, value);

    let filearr = [];
    /* Show more button 
    filearr.push(
      ...(showMore
        ? dataValue && Array.isArray(dataValue) && dataValue.slice(0, parseInt(5))
        : dataValue)
    ); */
    filearr = dataValue;

    if (filearr?.length > 0) {
      template = (
        <>
          <div className="dashboard-block file-container">
            {filearr?.map((file) => (
              <div className="file-upload">
                <div
                  onClick={() => download(file, apiEndPoint)}
                  onKeyUp={(event) =>
                    handleDownloadOnFocus(event, file, apiEndPoint)
                  }
                  className="file-list"
                  role="link"
                  title="opens in a modal window"
                  tabIndex={0}
                >
                  {file?.name}
                </div>
                {file?.type && getAllFiles && (
                  <>
                    <span className="separator-line" aria-hidden="true">
                      |
                    </span>
                    <span className="file-type">{file?.label || file?.type}</span>
                  </>
                )}
              </div>
            ))}
          </div>
          <Modal showModal={showModal} handleModal={handleDownloadModal} size="md">
            <div className="inner-modal-container" key="body">
              <h2>{t('download-confirmation')}</h2>
              <div className="confirm-button-container">
                <Button
                  cssClass="yes-button"
                  handleButtonClick={handleYes}
                  text={t('yes')}
                />
                <Button handleButtonClick={handleNo} text={t('no')} />
              </div>{' '}
            </div>
          </Modal>
        </>
      );
    }
    return template || [];
  };

  const getValueByFileTemplate = (item) => {
    const fieldObj = getFieldValues(item);
    let result = [];
    fieldObj?.name?.forEach(function (field) {
      result = parseFileValue(field, fieldObj.apiEndPoint, field === 'allFiles');
    });
    return result;
  };

  const getValueByBlockTemplate = (item) => {
    const fieldObj = getFieldValues(item);
    return (
      <div>
        {fieldObj?.label}
        <div className="skill-list-container">
          {formDetails?.[fieldObj?.name] && formDetails?.[fieldObj?.name].length > 0
            ? formDetails?.[fieldObj?.name]?.map((item) => (
                <div className="skill-item">{item}</div>
              ))
            : parse(
                missingText(
                  fieldObj?.label,
                  fieldObj?.subLabel[0],
                  fieldObj?.subLabel?.length
                )
              )}
        </div>
      </div>
    );
  };

  const checkConnectorFieldValidation = (connectorField, fieldName) => {
    const connectorValue = get(formDetails, connectorField);
    const fieldValue = get(formDetails, fieldName) || '';
    return connectorValue ? fieldValue.includes(connectorValue) : false;
  };
  const getDate = (dt) => {
    if (dt && dt !== null) {
      if (typeof dt === 'string' && dt.split('/').length > 1) {
        const dateVar = dt.split('/');
        const dateVal = new Date(dateVar[1] + '/' + dateVar[0] + '/' + dateVar[2]);
        return dateVal;
      } else if (moment(dt).isValid()) return dt;
      else {
        const theDate = new Date(parseInt(dt));
        const dateString = theDate.toGMTString();
        return dateString;
      }
    }
    return null;
  };

  const missingText = (label, subLabel = '', fieldLength) => {
    let result = '';
    if (label) {
      if (fieldLength > 1 && subLabel) {
        result += `${t('missing')} (${subLabel})`;
      } else {
        result += t('missing');
      }
    } else {
      result += subLabel ? `${t('missing')} (${subLabel})` : t('missing');
    }
    return `<span class="not-added-cls" >${result}</span>`;
  };

  const parseValue = (
    value,
    sep,
    fieldLabel,
    defaultValue,
    fieldType,
    options,
    fieldCount,
    subLabel,
    showMissingText
  ) => {
    const newValue = defaultValue ? defaultValue : get(formDetails, value);
    let result = '';
    switch (fieldType) {
      case 'customText':
        const convertedVal = newValue?.toString();
        result = replaceTextWithValues(options?.[convertedVal] || '', formDetails);
        break;
      default:
        result += newValue
          ? `${newValue}${sep} `
          : showMissingText !== 'false'
          ? `${missingText(fieldLabel, subLabel, fieldCount)}${sep} `
          : '';
        break;
    }
    return result;
  };

  const getValueByFieldName = (item, index) => {
    const fieldObj = getFieldValues(item);
    let options =
      item?.fields?.options && item?.fields?.options?.length > 0
        ? item?.fields?.options
        : [];
    options = defineKeyValueObjFromObjectArray(options);
    let result = '';
    fieldObj?.name?.forEach(function (value, i) {
      const sep = i !== fieldObj?.name?.length - 1 ? fieldObj?.separator : '';
      result += parseValue(
        value,
        sep,
        fieldObj?.label,
        fieldObj?.defaultValue,
        fieldObj?.type,
        options,
        fieldObj?.name?.length,
        fieldObj?.subLabel?.[i],
        fieldObj?.showMissingText?.[i]
      );
    });
    const headerClss = index === 0 ? 'header_item' : '';
    return result
      ? parse(
          `<div class="row"><div class="col textItem ${cssClass} ${headerClss}">${
            fieldObj?.label ? `${fieldObj?.label} ` : ''
          }${result}</div></div>`
        )
      : '';
  };

  const getValueByCustomValidator = (item) => {
    const fieldObj = getFieldValues(item);
    const hintText =
      item?.fields?.hintText?.value || item?.fields?.connectorMessage?.value;
    return checkConnectorFieldValidation(
      fieldObj?.connectorField?.[0],
      fieldObj?.name?.[0]
    ) ? (
      ''
    ) : get(formDetails, fieldObj?.name?.[0]) ? (
      <div className="readOnlyFieldWrapper">
        <div>{fieldObj?.label}</div>
        <div className="readOnlyField">
          {fieldObj?.defaultValue
            ? fieldObj?.defaultValue
            : get(formDetails, fieldObj?.name?.[0])}
          <TooltipComponent text={hintText} placement="left">
            <div className="error-info-icon">i</div>
          </TooltipComponent>
        </div>
      </div>
    ) : (
      ''
    );
  };

  const getDiff = (startDate, endDate) => {
    const startYear = moment(startDate).format('YYYY');
    const endYear = moment(endDate).format('YYYY');
    const startMonth = moment(startDate).format('MM');
    const endMonth = moment(endDate).format('MM');
    const start = moment(`${startYear}-${startMonth}-1`);
    const end = moment(`${endYear}-${endMonth}-1`);
    const mn = end.diff(start, 'months');
    const months = mn % 12;
    end.add(months, 'months');

    const years = Math.floor(mn / 12);
    const yr = years ? `${years} ${t('yrs')}` : '';
    const sep = years && months ? ' • ' : '';
    const ms = months ? `${sep} ${months} ${t('mos')}` : '';
    const calDiff = yr == '' && ms == '' ? `` : `(${yr}${ms})`;
    return calDiff;
  };

  const checkValidDate = (date) => (date ? moment(date).isValid() : false);

  const getDateTemplate = (index, showPresent, item) => {
    const fieldObj = getFieldValues(item);
    const date = getDate(formDetails[fieldObj?.name?.[index]]);
    if (
      fieldObj?.connectorField?.[index] &&
      formDetails?.[fieldObj?.connectorField?.[index]] &&
      showPresent
    ) {
      return t('present');
    } else {
      if (showPresent && id === 0 && !(date && checkValidDate(date)))
        return t('present');
      const res =
        date && checkValidDate(date)
          ? moment(date).format(
              fieldObj?.format?.[index] ? fieldObj?.format?.[index] : 'MMM YYYY'
            )
          : fieldObj?.showMissingText?.[index] !== 'false'
          ? missingText(
              fieldObj?.label,
              fieldObj?.subLabel?.[index],
              fieldObj?.subLabel?.length
            )
          : '';
      return res;
    }
  };

  const getValueByYear = (item) => {
    const fieldObj = getFieldValues(item);
    const stDate = getDate(formDetails[fieldObj?.name?.[0]]);
    const enDate = getDate(formDetails[fieldObj?.name?.[1]]);
    const startDate = getDateTemplate(0, false, item);
    const endDate = getDateTemplate(1, true, item);
    const diff =
      ((fieldObj?.connectorField?.[1] &&
        formDetails?.[fieldObj?.connectorField?.[1]]) ||
        endDate === t('present')) &&
      checkValidDate(stDate)
        ? getDiff(stDate, moment().format())
        : fieldObj?.connectorField?.[0] &&
          formDetails?.[fieldObj?.connectorField?.[0]] &&
          checkValidDate(enDate)
        ? getDiff(enDate, moment().format('MMM YYYY'))
        : checkValidDate(stDate) && checkValidDate(enDate)
        ? getDiff(stDate, enDate)
        : '';
    const sep = startDate && endDate ? fieldObj?.separator : '';
    const res =
      (stDate && checkValidDate(stDate)) || (enDate && checkValidDate(enDate))
        ? !!isSwiss
          ? `${startDate} ${sep} ${endDate}`
          : `${startDate} ${sep} ${endDate} ${diff}`
        : missingText(
            fieldObj?.label,
            fieldObj?.subLabel?.join(','),
            fieldObj?.subLabel?.length
          );
    const result = res
      ? parse(
          `<div class="row"><div class="col textItem">${
            fieldObj?.label ? `${fieldObj?.label} ` : ''
          }${res}</div></div>`
        )
      : '';
    return result;
  };

  const getValueByDateConvert = (item) => {
    const fieldObj = getFieldValues(item);
    const newValue = fieldObj?.defaultValue
      ? fieldObj?.defaultValue
      : get(formDetails, fieldObj?.name?.[0]);
    let result = newValue ? moment(newValue).format(fieldObj?.singleDateFormat) : '';
    const res = result
      ? result
      : fieldObj?.showMissingText?.[0] !== 'false'
      ? missingText(
          fieldObj?.label,
          fieldObj?.subLabel[0],
          fieldObj?.subLabel?.length
        )
      : '';
    result = res
      ? parse(
          `<div class="row"><div class="col textItem">${
            fieldObj?.label ? `${fieldObj?.label} ` : ''
          }${res}</div></div>`
        )
      : '';
    return result;
  };

  const getValueByPhoneConvert = (item) => {
    const fieldObj = getFieldValues(item);
    const phone = get(formDetails, fieldObj?.name?.[0]);
    const phoneNumber =
      phone && sitecoreContext?.Country?.code
        ? isNaN(phone)
          ? phone
          : parsePhoneNumber(
              phone,
              sitecoreContext?.Country?.code?.toUpperCase()
            )?.formatInternational()
        : '';
    const res = phoneNumber
      ? phoneNumber
      : missingText(
          fieldObj?.label,
          fieldObj?.subLabel[0],
          fieldObj?.subLabel?.length
        );
    const result = res
      ? parse(
          `<div class="row"><div class="col textItem">${
            fieldObj?.label ? `${fieldObj?.label} ` : ''
          }${res}</div></div>`
        )
      : '';
    return result;
  };

  const getTemplate = (item, index) => {
    const type = getItemValues(item, 'type');
    let result = '';
    switch (type) {
      case 'file':
        result = getValueByFileTemplate(item);
        break;
      case 'block':
        result = getValueByBlockTemplate(item);
        break;
      case 'yearDiff':
        result = item?.fields?.name?.value ? getValueByYear(item) : '';
        break;
      case 'customValidation':
        result = getValueByCustomValidator(item);
        break;
      case 'dateConvert':
        result = getValueByDateConvert(item);
        break;
      case 'phone':
        result = getValueByPhoneConvert(item);
        break;
      case 'address':
        result = getAddress(item);
        break;
      default:
        result = getValueByFieldName(item, index);
        break;
    }
    return result;
  };

  return (
    <div className="container">
      {block?.fields?.title?.value && (
        <div className="row">
          <div className="col">
            <label>{block?.fields?.title?.value}</label>
            {block?.fields?.hintText?.value &&
              (block.fields?.ctaText?.value ? (
                <span className="hinteText">
                  {' '}
                  {replaceTextwithJSX(
                    block?.fields?.hintText?.value,
                    '{0}',
                    <Link
                      key={`personalInfo${itemNumber}`}
                      to={block?.fields?.ctaUrl?.value?.href}
                    >
                      {block?.fields?.ctaText?.value}
                    </Link>
                  )}
                </span>
              ) : (
                <span className="hinteText">{block?.fields?.hintText?.value}</span>
              ))}
          </div>
        </div>
      )}
      {block &&
        block?.fields?.items?.map((item, i) =>
          item?.fields?.name?.value ? getTemplate(item, i) : ''
        )}
    </div>
  );
};

ProfileItemTemplate.defaultProps = {
  cssClass: '',
};

ProfileItemTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  cssClass: PropTypes.string,
};

export default withSitecoreContext()(ProfileItemTemplate);
