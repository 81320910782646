import React, { useState } from 'react';
import {
  Placeholder,
  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import PreferredLanguageModal from './components/core/PreferredLanguageModal';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import 'bootstrap/dist/css/bootstrap.css';
import './layout.scss';
import MissingComponent from './components/core/MissingComponent';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/min/locales';
import { STRING_VALUES } from './constants';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
/**

 * @param {*} value - value
 * @returns {action} - action
 */
const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
/**
 * @param {*} action - action
 * @returns {*} - action
 */
const useLocationChange = (action) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  React.useEffect(() => {
    action(location, prevLocation);
  }, [location]);
};

/**
 * @description - Base Layout for application
 * @param {object} param - Input prop with route object.
 * @returns {Node} - React JSX element.
 */
const Layout = ({ route, brand, language }) => {
  moment.locale(language);
  const brandName = brand?.toLowerCase() || '';
  const [prevLocation, setPrevLocation] = useState('');
  useLocationChange((location, prevLocation) => {
    setPrevLocation(prevLocation?.pathname);
  });
  const inputProps = {
    missingComponentComponent: MissingComponent,
    prevLocation,
    rendering: route,
    brandName,
    carouselProps:
      brandName === STRING_VALUES.MANPOWER
        ? undefined // Change this if vertical carosel is need in manpower as well (Performance)
        : {
            missingComponentComponent: MissingComponent,
            prevLocation,
            rendering: route,
            brandName,
          },
  };

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      {/* root placeholder for the app, which we add components to using route data */}
      <div className={`main-container ${brandName}`}>
        <Placeholder name="jss-header" {...inputProps} />
        <div id="main" className="contents" role="main">
          <Placeholder name="jss-main" {...inputProps} />

          <Placeholder name="jss-footer" {...inputProps} />
        </div>
        {/**Added model for swiss */}
        <PreferredLanguageModal />
      </div>
    </React.Fragment>
  );
};
Layout.defaultProps = {
  route: '',
  brand: '',
  language: '',
};
Layout.propTypes = {
  route: PropTypes.string,
  brand: PropTypes.string,
  language: PropTypes.string,
};
export default Layout;
